import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert';
import { Box, css, Spinner } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import FormLegal from '@solid-ui-components/ContentForm/FormLegal'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'

const styles = {
  form: {
    position: `relative`
  },
  responseOverlay: {
    position: `absolute`,
    backgroundColor: `transparent`,
    width: `full`,
    height: `105%`,
    transition: `background-color 350ms ease-in`,
    textAlign: `center`,
    zIndex: -1,
    p: 3,
    top: 0,
    left: 0,
    active: {
      zIndex: 0,
      backgroundColor: `rgba(255,255,255,0.85)`
    }
  },
  buttonsWrapper: {
    display: `inline-flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    '.button-group-button + .button-group-link': {
      flex: `100%`,
      ml: 0,
      mt: 3
    }
  }
}

const ContentForm = ({ id, form: { action, fields, buttons } = {} }) => {
  const { handleSubmit, submitting, success, setSubmitting, setSuccess } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = id

  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues])

  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    })
  }

  const sendContactForm = (e) =>{
    console.log(formId)
    e.preventDefault()

    let data = formValues[formId] || {}
    if(!data.hasOwnProperty('name') || data.name.trim() === ''){
      showAlert('Ingresa tu nombre', 'error');
    }else if(!data.hasOwnProperty('email') || data.email.trim() === ''){
      showAlert('Ingresa tu email', 'error');
    }else if(!data.hasOwnProperty('message') || data.message.trim() === ''){
      showAlert('Escribe un mensaje', 'error');
    }else {
      handleSubmit(e, { values: formValues[formId], action })

      fetch('https://getform.io/f/05ded78e-9c89-47c4-a377-84493b30e629', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => console.log('Success:', response))
    }
  }

  function showAlert(message, type) {
    swal(
      "Información!", message, type,
      {
        buttons:{
          cancel: 'Cerrar'
        }
      },
    );
  }

  const sendRegisterForm = (e) =>{
    e.preventDefault()
    let data = formValues[formId] || {}

    if(!data.hasOwnProperty('name') || data.name.trim() === ''){
      showAlert('Ingresa el nombre de tu restaurante', 'error');
    }else if(!data.hasOwnProperty('email') || data.email.trim() === ''){
      showAlert('Ingresa tu correo electrónico', 'error');
    }else if(!data.hasOwnProperty('password') || data.password.trim() === '' ){
      showAlert('Ingresa una contraseña', 'error');
    }else if(data.password.trim().length < 8 ){
      showAlert('Su contraseña debe tener al menos 8 caracteres', 'error');
    }else{
      setSubmitting(false)

      data.user = {
        email: data.email,
        password: data.password
      }

      fetch('https://api.quipuu.com/stores/create', {
        method: 'POST',
        body: JSON.stringify(data),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if(response.create){
            setSuccess(true)
            window.location.replace("https://app.quipuu.com/activation/" + response.token)
          }else{
            showAlert(response.message, 'error');
          }
        });
    }
  }

  const onSubmit = e => {
    if(formId.includes('form.contact')){
      sendContactForm(e)
    }
    if(formId.includes('form.authentication')){
      sendRegisterForm(e)
    }
  }

  return (
    <form
      css={css(styles.form)}
      onSubmit={onSubmit}
      method='POST'
      action={action}
      demo={action ? undefined : 'demo'}
    >
      <Box variant='forms.row'>
        {fields?.map(({ identifier, value, ...props }, index) => {
          let Component
          switch (props.type) {
            case 'PASSWORD':
            case 'EMAIL':
            case 'TEXT':
              Component = FormInput
              break
            case 'TEXTAREA':
              Component = FormTextarea
              break
            case 'CHECKBOX':
              Component = FormCheckbox
              break
            case 'LEGAL':
              Component = FormLegal
              break
            case 'HIDDEN':
              Component = FormHidden
              break
            default:
              break
          }

          return (
            <Box
              key={`index-${index}`}
              variant={props.compact ? 'forms.compact' : 'forms.full'}
            >
              <Component
                {...props}
                onChange={onChange}
                name={identifier}
                id={`${formId}.${identifier}`}
                value={formValues?.[formId]?.[identifier] || value || undefined}
              />
            </Box>
          )
        })}
      </Box>
      <Box sx={{ textAlign: `center` }}>
        <ContentButtons
          content={buttons}
          wrapperStyles={styles.buttonsWrapper}
        />
      </Box>
      <Box
        sx={styles.responseOverlay}
        css={(submitting || success) && styles.responseOverlay.active}
      >
        {submitting && (
          <Reveal effect='fadeInDown'>
            <Spinner size='64' color='alpha' />
          </Reveal>
        )}
        {success === true && (
          <Reveal effect='fadeInDown'>
            <BiCheckCircle size='64' css={css({ color: `success` })} />
          </Reveal>
        )}
        {success === false && (
          <BiErrorCircle size='64' css={css({ color: `error` })} />
        )}
      </Box>
    </form>
  )
}

export default ContentForm

ContentForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
