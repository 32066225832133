import React from 'react';
import { Text, Link } from 'theme-ui'

function FormLegal() {
  return <Text
    sx={{fontSize: 2, textAlign: 'center'}}>
    Al registrarte estás aceptando nuestros <a href='https://docs.google.com/document/d/e/2PACX-1vTAdMZEuXGxTrwDHgk224QkY82LZtnzqJawihbvJmUYBKYm50oWC0cKO-0-5B29RhC3vLKUer0pArww/pub' target='_blank' style={{textDecoration: 'none', color:'#00D1C0'}}>términos y condiciones</a>
  </Text>;
}

export default FormLegal;