import React from 'react'
import { Global } from '@emotion/core'
import Helmet from 'react-helmet'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import baseTheme from '@solid-ui-theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
import ColorMode from '@solid-ui-components/ColorMode'

const Layout = ({ children, pageContext = {}, location, theme = {} }) => {
  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                <ColorMode />
                <Helmet>
                  <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "58c9058cb07946dc86d8973ae8b899da"}'></script>
                  <script>
                    {`
                    var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?87114';
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = url;
                    var options = {
                    "enabled":true,
                    "chatButtonSetting":{
                    "backgroundColor":"#128C7E",
                    "ctaText":"",
                    "borderRadius":"25",
                    "marginLeft":"6",
                    "marginBottom":"50",
                    "marginRight":"6",
                    "position":"right"
                  },
                    "brandSetting":{
                    "brandName":"Quipuu",
                    "brandSubTitle":"Respondemos al instante!",
                    "brandImg":"https://ik.imagekit.io/quipuu/quipuu/logos/quipuu_logo_square.png",
                    "welcomeText":"Hola! 👋\\nEn que te podemos ayudar?",
                    "messageText":"Hola!, me pueden dar más información sobre Quipuu",
                    "backgroundColor":"#00a884",
                    "ctaText":"Contactar",
                    "borderRadius":"25",
                    "autoShow":false,
                    "phoneNumber":"573178282898"
                  }
                  };
                    s.onload = function() {
                    CreateWhatsappChatWidget(options);
                  };
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                    `}

                  </script>
                </Helmet>
                <Box variant='layout.body'>{children}</Box>
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

export default Layout
